<template>
  <div class="topPadding">
    <el-tabs v-model="activeName" @tab-click="handleClick">

      <el-tab-pane label="群聊被动回复" name="first">
        <div class="app-container">
          <el-form
              ref="form"
              :model="groupPassiveReplyForm"
              :rules="rules"
              size="small"
              label-width="300px"
              label-position="left"
          >
            <el-form-item label="是否开启：">
              <el-radio v-model="groupPassiveReplyForm.public_chat_group_passive_reply_enable" label="true">开启</el-radio>
              <el-radio v-model="groupPassiveReplyForm.public_chat_group_passive_reply_enable" label="false">关闭</el-radio>
            </el-form-item>

            <h3>1. 对话时机</h3>
            <el-form-item label="回复周期/s：">
              <el-input v-model="groupPassiveReplyForm.public_chat_group_passive_reply_period" style="width: 370px" />
            </el-form-item>

            <h3>2. 对话优先级</h3>
            <el-form-item label="亲密度top：">
              <el-input v-model="groupPassiveReplyForm.public_chat_group_passive_reply_intimacy_topn" style="width: 370px" />
            </el-form-item>
            <el-form-item label="魅力榜top：">
              <el-input v-model="groupPassiveReplyForm.public_chat_group_passive_reply_hot_topn" style="width: 370px" />
            </el-form-item>

            <h3>3. 上下文理解</h3>
            <el-form-item label="读取上文条数：">
              <el-input v-model="groupPassiveReplyForm.public_chat_group_passive_reply_history_limit" style="width: 370px" />
            </el-form-item>

            <h3>4. prompt调控</h3>
            <el-form-item
                v-for="(item, index) in groupPassiveReplyForm.public_chat_group_passive_reply_prompts_arr"
                :key="index"
                :label="'prompt' +(index + 1) + ': '"
                :prop="'public_chat_group_passive_reply_prompts_arr[' + index + ']'"
                :rules="{
            required: true, message: 'prompt不能为空', trigger: 'blur'
              }">
              <el-input
                  style="width: 370px"
                  type="textarea"
                  rows="4"
                  v-model="groupPassiveReplyForm.public_chat_group_passive_reply_prompts_arr[index]"></el-input>
              <el-button v-if="groupPassiveReplyForm.public_chat_group_passive_reply_prompts_arr.length > 1" type="danger" style="margin-left: 10px"  @click.prevent="removeGroupPassiveReplyPrompt(index)">删除</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="addGroupPassiveReplyPrompt" type="success" :disabled="groupPassiveReplyForm.public_chat_group_passive_reply_prompts_arr.length >= 5">继续添加</el-button>
            </el-form-item>


            <el-form-item label="">
              <el-button type="primary" @click="doSubmitGroupPassiveReplyForm()">保 存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>

      <el-tab-pane label="主动发起问候" name="second">
        <div class="app-container">
          <el-form
              ref="form"
              :model="groupActiveGreetingForm"
              :rules="rules"
              size="small"
              label-width="300px"
              label-position="left"
          >
            <el-form-item label="是否开启：">
              <el-radio v-model="groupActiveGreetingForm.public_chat_group_active_greeting_enable" label="true">开启</el-radio>
              <el-radio v-model="groupActiveGreetingForm.public_chat_group_active_greeting_enable" label="false">关闭</el-radio>
            </el-form-item>

            <h3>1. 对话时机</h3>
            <el-form-item label="回复周期/h：">
              <el-input v-model="groupActiveGreetingForm.public_chat_group_active_greeting_period" style="width: 370px" />
            </el-form-item>

            <h3>2. 对话优先级</h3>
            <el-form-item label="亲密度top：">
              <el-input v-model="groupActiveGreetingForm.public_chat_group_active_greeting_intimacy_topn" style="width: 370px" />
            </el-form-item>
            <el-form-item label="魅力榜top：">
              <el-input v-model="groupActiveGreetingForm.public_chat_group_active_greeting_hot_topn" style="width: 370px" />
            </el-form-item>

            <el-form-item label="">
              <el-button type="primary" @click="doSubmitGroupActiveGreetingForm()">保 存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>

      <el-tab-pane label="群聊被动回复" name="three">
        <div class="app-container">
          <el-form
              ref="form"
              :model="groupChatForm"
              :rules="rules"
              size="small"
              label-width="300px"
              label-position="left"
          >
            <el-form-item label="是否开启：">
              <el-radio v-model="groupChatForm.public_chat_group_enable" label="true">开启</el-radio>
              <el-radio v-model="groupChatForm.public_chat_group_enable" label="false">关闭</el-radio>
            </el-form-item>

            <h3>1. 对话时机</h3>
            <el-form-item label="群聊频次/min：">
              <el-input v-model="groupChatForm.public_chat_group_frequency" style="width: 370px" >
                <template slot="prepend">>=</template>
              </el-input>
            </el-form-item>
            <el-form-item label="参与周期/h：">
              <el-input v-model="groupChatForm.public_chat_group_period" style="width: 370px" />
            </el-form-item>

            <h3>2. 上下文理解</h3>
            <el-form-item label="读取上文条数：">
              <el-input v-model="groupChatForm.public_chat_group_history_limit" style="width: 370px" />
            </el-form-item>

            <h3>3. prompt调控</h3>
            <el-form-item
                v-for="(item, index) in groupChatForm.public_chat_group_prompts_arr"
                :key="index"
                :label="'prompt' +(index + 1) + ': '"
                :prop="'public_chat_group_prompts_arr[' + index + ']'"
                :rules="{
            required: true, message: 'prompt不能为空', trigger: 'blur'
              }">
              <el-input
                  style="width: 370px"
                  type="textarea"
                  rows="4"
                  v-model="groupChatForm.public_chat_group_prompts_arr[index]"></el-input>
              <el-button v-if="groupChatForm.public_chat_group_prompts_arr.length > 1" type="danger" style="margin-left: 10px"  @click.prevent="removeGroupChatPrompt(index)">删除</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="addGroupChatPrompt" type="success" :disabled="groupChatForm.public_chat_group_prompts_arr.length >= 5">继续添加</el-button>
            </el-form-item>


            <el-form-item label="">
              <el-button type="primary" @click="doSubmitGroupChatForm()">保 存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      activeName: "first",
      dataList: [],
      groupPassiveReplyForm: {
        public_chat_group_passive_reply_enable: '',
        public_chat_group_passive_reply_period: '',
        public_chat_group_passive_reply_history_limit: '',
        public_chat_group_passive_reply_intimacy_topn: '',
        public_chat_group_passive_reply_hot_topn: '',
        public_chat_group_passive_reply_prompts: '',
        public_chat_group_passive_reply_prompts_arr: []
      },
      groupActiveGreetingForm: {
        public_chat_group_active_greeting_enable: '',
        public_chat_group_active_greeting_period: '',
        public_chat_group_active_greeting_intimacy_topn: '',
        public_chat_group_active_greeting_hot_topn: ''
      },
      groupChatForm: {
        public_chat_group_enable: '',
        public_chat_group_frequency: '',
        public_chat_group_period: '',
        public_chat_group_history_limit: '',
        public_chat_group_prompts: '',
        public_chat_group_prompts_arr: []
      },

      rules: {},
      virtualList: [],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
    },
    // 获取数据列表
    getDataList() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this;
      this.$http({
        url: this.$http.adornUrl("/sys/config/list"),
        method: "get",
        params: this.$http.adornParams({
          page: 1,
          limit: 1000,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          data.page.list.map(function (key, value) {
            const keyName = key.paramKey;
            const newValue = key.paramValue;
            if (keyName in that.groupPassiveReplyForm) {
              that.groupPassiveReplyForm[keyName] = newValue;
            } else if (keyName in that.groupActiveGreetingForm) {
              that.groupActiveGreetingForm[keyName] = newValue;

            } else if (keyName in that.groupChatForm) {
              that.groupChatForm[keyName] = newValue;
            }
          });

          this.groupPassiveReplyForm.public_chat_group_passive_reply_period = parseInt(this.groupPassiveReplyForm.public_chat_group_passive_reply_period);
          this.groupPassiveReplyForm.public_chat_group_passive_reply_history_limit = parseInt(this.groupPassiveReplyForm.public_chat_group_passive_reply_history_limit);
          this.groupPassiveReplyForm.public_chat_group_passive_reply_intimacy_topn = parseInt(this.groupPassiveReplyForm.public_chat_group_passive_reply_intimacy_topn);
          this.groupPassiveReplyForm.public_chat_group_passive_reply_hot_topn = parseInt(this.groupPassiveReplyForm.public_chat_group_passive_reply_hot_topn);
          this.groupPassiveReplyForm.public_chat_group_passive_reply_prompts_arr = JSON.parse(this.groupPassiveReplyForm.public_chat_group_passive_reply_prompts);

          this.groupActiveGreetingForm.public_chat_group_active_greeting_period = parseInt(this.groupActiveGreetingForm.public_chat_group_active_greeting_period);
          this.groupActiveGreetingForm.public_chat_group_active_greeting_intimacy_topn = parseInt(this.groupActiveGreetingForm.public_chat_group_active_greeting_intimacy_topn);
          this.groupActiveGreetingForm.public_chat_group_active_greeting_hot_topn = parseInt(this.groupActiveGreetingForm.public_chat_group_active_greeting_hot_topn);

          this.groupChatForm.public_chat_group_frequency = parseInt(this.groupChatForm.public_chat_group_frequency);
          this.groupChatForm.public_chat_group_period = parseInt(this.groupChatForm.public_chat_group_period);
          this.groupChatForm.public_chat_group_history_limit = parseInt(this.groupChatForm.public_chat_group_history_limit);
          this.groupChatForm.public_chat_group_prompts_arr = JSON.parse(this.groupChatForm.public_chat_group_prompts);
        }
      });
    },
    doSubmitGroupPassiveReplyForm(){
      this.groupPassiveReplyForm.public_chat_group_passive_reply_prompts = JSON.stringify(this.groupPassiveReplyForm.public_chat_group_passive_reply_prompts_arr)
      this.doSubmit(this.groupPassiveReplyForm)
    },
    doSubmitGroupActiveGreetingForm(){
      this.doSubmit(this.groupActiveGreetingForm)
    },
    doSubmitGroupChatForm(){
      this.groupChatForm.public_chat_group_prompts = JSON.stringify(this.groupChatForm.public_chat_group_prompts_arr)
      this.doSubmit(this.groupChatForm)
    },
    doSubmit(form) {
      this.$http({
        url: this.$http.adornUrl(`/sys/config/updateBatch`),
        method: "post",
        data: form,
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: "设置成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.$emit("refreshDataList");
            },
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(
              `/sys/config/${!this.dataForm.id ? "save" : "update"}`
            ),
            method: "post",
            data: this.$http.adornData({
              id: this.dataForm.id || undefined,
              paramKey: this.dataForm.paramKey,
              paramValue: this.dataForm.paramValue,
              remark: this.dataForm.remark,
            }),
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    },
    removeGroupPassiveReplyPrompt(index) {
      this.groupPassiveReplyForm.public_chat_group_passive_reply_prompts_arr.splice(index, 1)
    },
    addGroupPassiveReplyPrompt() {
      this.groupPassiveReplyForm.public_chat_group_passive_reply_prompts_arr.push("")
    },
    removeGroupChatPrompt(index) {
      this.groupChatForm.public_chat_group_prompts_arr.splice(index, 1)
    },
    addGroupChatPrompt() {
      this.groupChatForm.public_chat_group_prompts_arr.push("")
    }
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px 20px 45px 20px;
}
.topPadding {
  padding-left: 30px;
}

.formInfo {
  line-height: 0px;
  color: #999999;
  font-size: 12px;
}
.notice {
  line-height: 0px;
  color: #656161;
}
.avatar-uploader .el-upload {
  border: 3px dashed #979494;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.avatar2 {
  width: 200px;
  height: 80px;
  display: block;
}

.c-mr10 {
  margin-right: 10px !important;
}

.el-tag{
  white-space: normal;
  height:auto;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
}

.el-divider {
  background-color: #dcdfe6;
  position: relative;
}

.el-divider__text {
  position: absolute;
  background-color: #fff;
  padding: 0 20px;
  font-weight: 500;
  color: #303133;
  font-size: 14px;
}

.el-divider__text.is-right {
  right: 20px;
  transform: translateY(-50%);
}

</style>
